import { Dialog } from '@headlessui/react';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { DsButton } from '../../../../spedire-design-system/lib';
import { InputField } from '../../components/form';
import { Modal, ModalProps } from '../../components/modal';
import { login, LoginDTO, LoginResult } from '../../features/auth';
import { showToast } from '../../features/toasts';
import { Remove } from '../../icons';
import { TrackingModals } from '../../pages/tracking/[trackingId]';

export const LoginModal: React.FC<
  ModalProps & { setActiveModal: React.Dispatch<React.SetStateAction<TrackingModals>>; onLogin?: () => void }
> = ({ setActiveModal, onLogin, ...modalProps }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const form = useForm<LoginDTO>({});
  const { handleSubmit } = form;

  const [erroFromBackend, setErrorFromBackend] = useState<boolean>(false);

  return (
    <Modal open={true} {...modalProps}>
      <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
        <button
          type="button"
          className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
          onClick={modalProps.onClose}
        >
          <span className="sr-only">{t('screen_reader.close')}</span>
          <Remove className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>

      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
        {t('ticket.registration.title')}
      </Dialog.Title>
      <p className="text-gray-600 text-base leading-6 font-normal mt-2">{t('ticket.login.title')}</p>
      <div className="mt-2">
        <FormProvider {...form}>
          <form
            onSubmit={handleSubmit(async (data) => {
              setIsLoading(true);
              // dispatch typing is broken when using thunks, this should be the only case we need this
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const action = ((await dispatch(login(data))) as any) as { payload: LoginResult };
              if (action.payload.message) {
                setErrorFromBackend(true);
                showToast({ text: action.payload.message, title: t('toast.error'), type: 'error' });
              } else {
                onLogin && onLogin();
              }
              setIsLoading(false);
            })}
          >
            <InputField name="email" label={t('ticket.login.email.label')} required />

            <InputField name="password" type="password" label={t('ticket.registration.password.label')} required />

            {erroFromBackend && (
              <div className="text-red-500 text-sm leading-5 font-normal">
                <p className="mb-6">{t('ticket.login.error_message_1')}</p>
                <p className="mb-6">{t('ticket.login.error_message_2')}</p>
              </div>
            )}

            <DsButton styleType="primary" className="w-full" type="submit" loading={isLoading}>
              {t('ticket.login.button')}
            </DsButton>

            <DsButton onClick={() => setActiveModal(TrackingModals.signUp)} className="w-full" styleType="text">
              {t('ticket.login.no_account_sign_up')}
            </DsButton>
          </form>
        </FormProvider>
      </div>
    </Modal>
  );
};
