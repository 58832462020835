import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { navigate } from 'gatsby';
import { groupBy, orderBy } from 'lodash/fp';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IpadContentContainer } from '../../components/ipadContentContainer';
import { userSelector } from '../../features/auth';
import { DefaultTrackingMetadata } from '../../features/helmetMetadata';
import { TicketDetail, useGetTrackingQuery, useGetUserTicketsQuery } from '../../features/ticket/ticketApi';
import { SearchInput } from '../../molecules/index/hero/searchInput';
import { RecentShippings } from '../../molecules/index/recentShippings';
import { upsertRecentShipping } from '../../molecules/index/recentShippings/recentShippingsLogic';
import { Layout } from '../../molecules/layout';
import { LoadingContent, LoadingHeader } from '../../molecules/tracking/loading';
import { TrackingHeader } from '../../molecules/tracking/TrackingHeader';
import { getStatusInfo, StatusType } from '../../molecules/tracking/utils';
import { AssistanceConfirmationModal } from '../../molecules/trackingId/AssistanceConfirmationModal';
import { ExplanationModal } from '../../molecules/trackingId/ExplanationModal';
import { InformativeBox } from '../../molecules/trackingId/informativeBox';
import { Log } from '../../molecules/trackingId/log';
import { LoginModal } from '../../molecules/trackingId/LoginModal';
import { ShipmentNotFound } from '../../molecules/trackingId/shipmentNotFound';
import { SignUpForAssistanceModal } from '../../molecules/trackingId/SignUpForAssistanceModal';
import { Stepper } from '../../molecules/trackingId/stepper';
import { ILog } from '../../molecules/trackingId/types';
import { createSteps } from '../../molecules/trackingId/utils';
import { WrongAccountModal } from '../../molecules/trackingId/WrongAccountModal';
import { useIntercom } from '../../useIntercom';

export enum statuses {
  complete = 'complete',
  current = 'current',
  warning = 'warning',
  upcoming = 'upcoming'
}

export enum TrackingModals {
  assistanceConfirmation = 'assistanceConfirmation',
  signUp = 'signUp',
  logIn = 'logIn',
  explanation = 'exlapation',
  wrongAccount = 'wrongAccoung'
}

const TrackingDataPage = ({ params }) => {
  const user = useSelector(userSelector);

  const [activeModal, setActiveModal] = useState<TrackingModals | null>();

  const { t } = useTranslation();

  const { data: response, isFetching, isError, isSuccess } = useGetTrackingQuery({ trackingCode: params.trackingId });

  const ticketsQuery = useGetUserTicketsQuery({ tracking_code: params.trackingId }, { skip: !user });

  const { closedTickets, openTickets } = useMemo(
    () =>
      groupBy<TicketDetail>((t) => (t.status.is_closed ? 'closedTickets' : 'openTickets'))(ticketsQuery?.data?.data),
    [ticketsQuery.data]
  );

  const trackingData = response?.data;

  const statusInfo = trackingData && getStatusInfo(t)(trackingData);

  useIntercom(statusInfo?.type !== StatusType.PROBLEM_USER_SOLVABLE);

  useEffect(() => {
    if (isSuccess && trackingData?.id)
      upsertRecentShipping({
        sender: trackingData.sender.city,
        recipient: trackingData.recipient.city,
        trackingCode: params.trackingId
      });
  }, [isSuccess, trackingData?.id]);

  const renderContent = () => {
    if (isError) {
      return <ShipmentNotFound trackingId={params.trackingId} />;
    }

    return (
      <>
        {!isFetching && trackingData.merchant && trackingData.merchant.logo_url ? (
          <div className="flex justify-center pt-14">
            <div
              className="p-4 rounded-lg inline-block"
              style={{ backgroundColor: trackingData.merchant.color_secondary }}
            >
              <a href={trackingData.merchant?.website} target="_blank" rel="noreferrer">
                <div className="w-52 h-20 flex justify-center">
                  <img
                    className="object-contain"
                    src={trackingData.merchant.logo_url}
                    alt={`${trackingData.merchant.name} logo`}
                  />
                </div>
              </a>
            </div>
          </div>
        ) : null}

        {isFetching ? (
          <LoadingHeader />
        ) : (
          <TrackingHeader
            statusInfo={statusInfo}
            tracking={trackingData}
            onAssistanceButtonClick={() => {
              if (user) {
                if (trackingData.can_create_ticket) {
                  navigate(`/ticket/new?trackingCode=${params.trackingId}`);
                } else {
                  setActiveModal(TrackingModals.wrongAccount);
                }
              } else {
                setActiveModal(TrackingModals.assistanceConfirmation);
              }
            }}
            openTickets={openTickets}
            closedTickets={closedTickets}
          />
        )}

        <div className="pb-8">
          <div className="px-4 py-8 md:p-8 lg:shadow-lg lg:rounded-md border-t lg:border border-gray-200 bg-white">
            {isFetching ? (
              <LoadingContent />
            ) : (
              <>
                <Stepper
                  steps={createSteps(trackingData.status.code, trackingData.delivered_at, trackingData.logs)}
                  merchantColor={trackingData.merchant?.color_primary}
                />

                <InformativeBox
                  trackingId={params.trackingId}
                  courierName={trackingData.courier.name}
                  courierLogoUrl={trackingData.courier.logo_url}
                  courierTrackingUrl={
                    trackingData.courier.tracking_url
                      ? trackingData.courier.tracking_url + params.trackingId
                      : undefined
                  }
                  sender={trackingData.sender}
                  recipient={trackingData.recipient}
                />

                <Log logs={orderBy<ILog>('date', 'desc')(trackingData.logs)} />

                {trackingData?.merchant ? null : (
                  <div className="md:w-2/3 mx-auto mt-10 md:mt-20">
                    <h3 className="text-2xl leading-7 font-bold">
                      {t('recent_trackings.looking_for_another_shipping')}
                    </h3>
                    <SearchInput
                      inputPlaceHolder={t('tracking_page.searchAnotherShipment.inputPlaceHolder')}
                      searchButtonText={t('tracking_page.searchAnotherShipment.searchButtonText')}
                      mode="exposed"
                      merchantColor={trackingData.merchant?.color_primary}
                    />

                    <RecentShippings
                      className="mt-8"
                      mode="exposed"
                      merchantColor={trackingData.merchant?.color_primary}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </>
    );
  };

  // forced for now so we can test it on all shipments
  // (for the first launch we'll use have merchant shipments)
  const shouldUseMerchanteFooter = true; //!!trackingData?.merchant

  return (
    <Layout isMerchantFooter={shouldUseMerchanteFooter} headerText={t('tracking.header_shipment_managed_by')}>
      <DefaultTrackingMetadata />
      <div
        className={`bg-gray-50 ${classNames({
          'min-h-screen': shouldUseMerchanteFooter
        })}`}
      >
        <Transition.Root show={!!activeModal} as={Fragment}>
          <Dialog open={!!activeModal} onClose={() => null}>
            <>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>
              <AssistanceConfirmationModal
                open={activeModal === TrackingModals.assistanceConfirmation}
                onClose={() => setActiveModal(null)}
                setActiveModal={setActiveModal}
                merchantName={trackingData?.merchant?.name}
                hideOverlay
              />
              <SignUpForAssistanceModal
                open={activeModal === TrackingModals.signUp}
                onClose={() => setActiveModal(null)}
                setActiveModal={setActiveModal}
                obfuscatedEmail={trackingData?.recipient?.email}
                trackingCode={params.trackingId}
                merchantName={trackingData?.merchant?.name}
                hideOverlay
              />
              <ExplanationModal
                open={activeModal === TrackingModals.explanation}
                onClose={() => setActiveModal(null)}
                setActiveModal={setActiveModal}
                obfuscatedEmail={trackingData?.recipient?.email}
                hideOverlay
              />
              <LoginModal
                open={activeModal === TrackingModals.logIn}
                onClose={() => setActiveModal(null)}
                setActiveModal={setActiveModal}
                onLogin={() => {
                  setActiveModal(null);
                  location.reload();
                }}
                hideOverlay
              />
              <WrongAccountModal
                open={activeModal === TrackingModals.wrongAccount}
                setActiveModal={setActiveModal}
                onClose={() => setActiveModal(null)}
                hideOverlay
              />
            </>
          </Dialog>
        </Transition.Root>

        <IpadContentContainer>{renderContent()}</IpadContentContainer>
      </div>
    </Layout>
  );
};

export default TrackingDataPage;
