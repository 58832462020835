import { addBusinessDays, endOfDay, isAfter } from 'date-fns';
import { parseDate } from '../../utils/parseDate';
import { statuses } from './const';
import { ILog, TStep } from './types';
import { reverse } from 'lodash';

export type StepLabelCode = 'waiting_for_pick_up' | 'started' | 'in_transit' | 'delivering' | 'delivered';

const stepsLabels: { labelCode: StepLabelCode }[] = [
  { labelCode: 'waiting_for_pick_up' },
  { labelCode: 'started' },
  { labelCode: 'in_transit' },
  { labelCode: 'delivering' },
  { labelCode: 'delivered' }
];

// Status codes clustered by step
const mappedCodes = [['0', '10', '20'], ['30'], ['40', '50', '60', '90'], ['70', '80'], ['160', '170', '180']];

export const createSteps = (code: string, delivered_at: string, logs: ILog[]): TStep[] => {
  // Define steps
  let steps = [];

  // Create initial array of steps
  for (let i = 0; i < stepsLabels.length; i++) {
    steps.push({ ...stepsLabels[i], status: statuses.upcoming });
  }

  let codeToUse = code;
  // Used in specific cases:
  // - last step but current status is a warning
  // - is current step but current status is a warning
  let variant = undefined;

  // Get a usable code if the current status code is a warning
  if (['100', '110', '120', '130', '140', '150'].includes(codeToUse)) {
    // Filter logs to get the latest not exception log
    const latestValidStepLog = reverse([...logs]).find(
      (log) => !['100', '110', '120', '130', '140', '150'].includes(log.code)
    );

    if (latestValidStepLog) {
      codeToUse = latestValidStepLog.code;
    }
    variant = statuses.warning;
  }

  // Get the index of the current step
  const currentStepIndex = mappedCodes.findIndex((codesCluster) => codesCluster.includes(codeToUse));

  // Update steps statuses using the current index and the codes
  steps = steps.map((step, i) => {
    // Mark as complete all the steps before the current one
    if (currentStepIndex > i) {
      return { ...step, status: statuses.complete };
    }

    // If the step is the current
    if (currentStepIndex === i) {
      // Check on last step if is 3 days after
      if (currentStepIndex === 4) {
        const postDeliveryAssistanceEndDate = endOfDay(addBusinessDays(parseDate(delivered_at), 3));
        if (isAfter(new Date(), postDeliveryAssistanceEndDate)) {
          return { ...step, status: statuses.complete, variant };
        }
      }

      if (['90', '80'].includes(codeToUse)) {
        return { ...step, status: statuses.warning, variant };
      }

      return { ...step, status: statuses.current, variant };
    }

    return step;
  });

  return steps;
};
