import { Dialog } from '@headlessui/react';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DsButton } from '../../../../spedire-design-system/lib';
import { InputField } from '../../components/form';
import { Markdown } from '../../components/markdown';
import { Modal, ModalProps } from '../../components/modal';
import { useSetApiErrorOnForm } from '../../features/api';
import { PostRegisterForTicketApiArg, useRegisterForTicketMutation } from '../../features/ticket/ticketApi';
import { EmailIcon, Remove, SuccessIcon } from '../../icons';
import { TrackingModals } from '../../pages/tracking/[trackingId]';

type SignUpFormFields = Omit<PostRegisterForTicketApiArg, 'tracking_code'>;

export const SignUpForAssistanceModal: React.FC<
  ModalProps & {
    obfuscatedEmail: string;
    setActiveModal: React.Dispatch<React.SetStateAction<TrackingModals>>;
    trackingCode: string;
    merchantName?: string;
  }
> = ({ obfuscatedEmail, setActiveModal, trackingCode, merchantName, ...modalProps }) => {
  const form = useForm<SignUpFormFields>();

  const [trigger, { isLoading, isSuccess, error }] = useRegisterForTicketMutation();

  const { t } = useTranslation();

  useSetApiErrorOnForm({ apiError: error, setError: form.setError });

  return (
    <Modal open={true} {...modalProps}>
      <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
        <button
          type="button"
          className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
          onClick={modalProps.onClose}
        >
          <span className="sr-only">{t('screen_reader.close')}</span>
          <Remove className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>

      {isSuccess ? (
        <div>
          <SuccessIcon className="mx-auto mb-10" />

          <h2 className="text-lg leading-6 font-medium mb-2">{t('ticket.registration.success_title')}</h2>
          <p className="mb-10 text-gray-600">
            <Markdown>{t('ticket.registration.success_text_1', { obfuscatedEmail })}</Markdown>
          </p>
          <p className="mb-8 text-gray-600">
            <Markdown>{t('ticket.registration.success_text_2')}</Markdown>
          </p>

          <DsButton styleType="primary" className="w-full" onClick={modalProps.onClose}>
            {t('ticket.registration.success_go_back')}
          </DsButton>
        </div>
      ) : (
        <>
          <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
            {t('ticket.registration.title')}
          </Dialog.Title>
          <div className="mt-2">
            <FormProvider {...form}>
              <form
                onSubmit={form.handleSubmit(async (data) => {
                  await trigger({
                    email: data.email,
                    password: data.password,
                    tracking_code: trackingCode,
                    name: data.name
                  });
                })}
              >
                <InputField
                  name="obfuscated_email"
                  label={t('ticket.registration.obfuscated_email.label')}
                  description={
                    merchantName
                      ? t('ticket.registration.obfuscated_email.description_merchant', { merchantName })
                      : t('ticket.registration.obfuscated_email.description')
                  }
                  leadingIcon={<EmailIcon className="w-4 h-4" />}
                  value={obfuscatedEmail}
                  disabled
                />

                <InputField name="email" label={t('ticket.registration.email.label')} required />

                <InputField name="name" label={t('ticket.registration.name.label')} required />

                <InputField name="password" type="password" label={t('ticket.registration.password.label')} required />

                <DsButton styleType="primary" className="w-full" type="submit" loading={isLoading}>
                  {t('ticket.registration.submit')}
                </DsButton>

                <div className="text-xs font-normal leading-4 text-gray-500 mt-3 pb-2">
                  <Markdown
                    components={{
                      a: (({ children, ...props }) => (
                        <a
                          className="underline cursor-pointer text-blue-600"
                          target="_blank"
                          rel="noreferrer"
                          {...props}
                        >
                          {children}
                        </a>
                      )) as React.FC
                    }}
                  >
                    {t('registration.tos_text')}
                  </Markdown>
                </div>
              </form>
              <DsButton
                type="button"
                onClick={() => setActiveModal(TrackingModals.logIn)}
                className="w-full"
                styleType="text"
              >
                {t('ticket.registration.login')}
              </DsButton>
            </FormProvider>
          </div>
        </>
      )}
    </Modal>
  );
};
