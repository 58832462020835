import React from 'react';
import { useTranslation } from 'react-i18next';
import { DsButton } from 'spedire-design-system';
import { Modal, ModalProps } from '../../components/modal';
import { Remove } from '../../icons';
import { TrackingModals } from '../../pages/tracking/[trackingId]';

import Illustration from '../../assets/shipping-a-package-illustration.svg';
import { Markdown } from '../../components/markdown';

export const AssistanceConfirmationModal: React.FC<
  ModalProps & { setActiveModal: React.Dispatch<React.SetStateAction<TrackingModals>>; merchantName?: string }
> = ({ setActiveModal, merchantName, ...modalProps }) => {
  const { t } = useTranslation();

  return (
    <Modal open={true} {...modalProps} size="md">
      <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
        <button
          type="button"
          className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
          onClick={modalProps.onClose}
        >
          <span className="sr-only">{t('screen_reader.close')}</span>
          <Remove className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <div className="flex flex-col">
        <Illustration className="mx-auto" />

        <h2 className="text-lg leading-6 font-medium text-gray-900 mb-1">{t('ticket.assistance_modal.title')}</h2>

        <p className="text-sm text-gray-600 mb-6">
          <Markdown>{t('ticket.assistance_modal.text_1')}</Markdown>
        </p>

        {merchantName && (
          <p className="text-sm text-gray-600 mb-6">
            <Markdown>{t('ticket.assistance_modal.text_2', { merchantName })}</Markdown>
          </p>
        )}
        <DsButton styleType="primary" onClick={() => setActiveModal(TrackingModals.signUp)} className="mb-2">
          {t('ticket.assistance_modal.button')}
        </DsButton>
      </div>
    </Modal>
  );
};
