import { CheckIcon, ExclamationIcon, TruckIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { statuses } from './const';
import { TStep } from './types';

const StepStatusIcon: React.FC<{ step: TStep; merchantColor: string }> = ({ step, merchantColor }) => {
  const commonStyle = 'relative mx-auto w-8 h-8 flex items-center justify-center';

  let borderColor = '#318823';
  if (step.variant === statuses.warning) {
    borderColor = '#F59E0B';
  } else if (merchantColor) {
    borderColor = merchantColor;
  }

  const isWarningVariant = (normalIcon) =>
    step.variant === statuses.warning ? (
      <ExclamationIcon className="w-5 h-5 text-yellow-600" aria-hidden="true" />
    ) : (
      normalIcon
    );

  switch (step.status) {
    case statuses.complete:
      return (
        <div
          className={`${commonStyle} border-2 ${
            step.variant === statuses.warning ? 'bg-white' : 'bg-green-600 border-green-600'
          } rounded-full`}
          {...(merchantColor
            ? {
                style: {
                  backgroundColor: step.variant === statuses.warning ? 'white' : merchantColor,
                  borderColor: borderColor
                }
              }
            : {})}
        >
          {isWarningVariant(<CheckIcon className="w-5 h-5 text-white" aria-hidden="true" />)}
        </div>
      );
    case statuses.warning:
      return (
        <div className={`${commonStyle} bg-white border-2 border-yellow-600 rounded-full `}>
          <ExclamationIcon className="w-5 h-5 text-yellow-600" aria-hidden="true" />
        </div>
      );
    case statuses.current:
      return (
        <div className={`${commonStyle} bg-white border-2 rounded-full `} style={{ borderColor: borderColor }}>
          {isWarningVariant(
            <TruckIcon
              className={`w-5 h-5 text-green-600`}
              aria-hidden="true"
              {...(merchantColor ? { style: { color: merchantColor } } : {})}
            />
          )}
        </div>
      );
    case statuses.upcoming:
      return (
        <div className={`${commonStyle} bg-white border-2 border-gray-300 rounded-full`}>
          <div className="w-5 h-5 " aria-hidden="true"></div>
        </div>
      );
    default:
      break;
  }
};

const StepStatusText: React.FC<{ label: string; status: statuses; merchantColor?: string; variant: statuses }> = ({
  label,
  status,
  merchantColor,
  variant
}) => {
  let textColor = '';
  let merchantStyleProp = {};

  if (!merchantColor) {
    // Standard colors
    switch (status) {
      case statuses.upcoming:
        textColor = 'text-gray-400';
        break;
      case statuses.warning:
        textColor = 'text-yellow-600';
        break;
      default:
        if (variant === statuses.warning) {
          textColor = 'text-yellow-600';
        } else {
          textColor = 'text-green-600';
        }
        break;
    }
  } else {
    if (!variant) {
      // Use main color of the merchant
      merchantStyleProp = { style: { color: merchantColor } };
    } else if (variant === statuses.warning) {
      textColor = 'text-yellow-500';
    }
  }

  return (
    <div className="inline-block w-28 md:block md:w-1/5 md:px-6 whitespace-normal">
      <p
        className={`text-xs leading-4 font-semibold tracking-wide uppercase text-center ${textColor}`}
        {...merchantStyleProp}
      >
        {label}
      </p>
    </div>
  );
};

const getCurrentStepPosition = (steps: TStep[]) =>
  steps.findIndex((step) => step.status === statuses.current || step.status === statuses.warning);

export const Stepper: React.FC<{ steps: TStep[]; merchantColor?: string }> = ({ steps, merchantColor }) => {
  const navRef = useRef<HTMLElement>(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (steps) {
      const currentStepIndex = getCurrentStepPosition(steps);
      if (currentStepIndex > 1 && navRef.current !== undefined) {
        navRef.current.scrollLeft = navRef.current.clientWidth;
      }
    }
  }, [steps]);

  return (
    <nav ref={navRef} aria-label="Progress" className="overflow-x-auto whitespace-nowrap md:overflow-auto">
      <div className="block md:flex">
        {steps.map((o, i) => {
          const nextElement = steps[i + 1];
          // Create the style for the lines between the steps
          let rightStyleProp = {};
          let rightColor = 'bg-gray-200';
          if (nextElement && nextElement.status !== statuses.upcoming) {
            rightStyleProp = { style: { backgroundColor: merchantColor, borderColor: merchantColor } };
            rightColor = 'bg-green-600';
          }

          let leftBackgroundColor = '#E8E8E8';

          if (
            o.status === statuses.complete ||
            o.status === statuses.warning ||
            (o.status === statuses.current && i !== 0) ||
            (i === 0 && nextElement.status !== statuses.upcoming)
          ) {
            if (merchantColor) {
              leftBackgroundColor = merchantColor;
            } else {
              leftBackgroundColor = '#318823';
            }
          }
          // end

          return (
            <div key={i} className="inline-block w-28 md:w-1/5 relative">
              <div className={`absolute flex items-center inset-0 w-full`} aria-hidden="true">
                <div
                  className={`h-0.5 w-1/2 ${classNames({ 'ml-auto': i === 0 })}`}
                  style={{ backgroundColor: leftBackgroundColor }}
                />
                {i !== steps.length - 1 && i !== 0 ? (
                  <div className={`h-0.5 w-1/2 ${i === 0 ? 'mr-auto' : ''} ${rightColor}`} {...rightStyleProp} />
                ) : null}
              </div>
              <StepStatusIcon step={o} merchantColor={merchantColor} />
            </div>
          );
        })}
      </div>
      <div className="md:flex mt-4">
        {steps.map(({ labelCode, status, variant }, i) => (
          <StepStatusText
            key={i}
            label={t(`tracking_page.stepper.stepLabel.${labelCode}`)}
            status={status}
            merchantColor={merchantColor}
            variant={variant}
          />
        ))}
      </div>
    </nav>
  );
};
