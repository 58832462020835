import { CalendarIcon, CashIcon, CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/solid';
import { format } from 'date-fns';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { DsButton } from '../../../../spedire-design-system/lib';
import { InfoBox } from '../../components/InfoBox';
import { Markdown } from '../../components/markdown';
import { TicketDetail } from '../../features/ticket/ticketApi';
import { ChatIcon } from '../../icons';
import { customLanguageDetector, dateFNSMappedLocales } from '../../strings/setup';
import { ClosedTicketBox } from './ClosedTicketBox';
import { OpenTicketBox } from './OpenTicketBox';
import { deliveredText, StatusInfo, StatusType, TrackingResponse } from './utils';

const BoldLabel = 'prose font-semibold';

const NormalText = 'prose prose-sm';

export const TrackingHeader: React.FC<{
  tracking: TrackingResponse['data'];
  statusInfo: StatusInfo;
  openTickets?: TicketDetail[];
  closedTickets?: TicketDetail[];
  onAssistanceButtonClick: () => void;
}> = ({ tracking, statusInfo, onAssistanceButtonClick, openTickets, closedTickets }) => {
  if (!statusInfo) return null;

  const { t } = useTranslation();

  const hasOpenTickets = openTickets?.length > 0;

  const assistanceBoxVisible = !hasOpenTickets && statusInfo.type === StatusType.PROBLEM_USER_SOLVABLE;
  const postdeliveryAssistanceBoxVisible =
    !assistanceBoxVisible && !hasOpenTickets && statusInfo.canAskForAssistancePostDelivery;
  const nonSolvableProblemBoxVisible =
    !assistanceBoxVisible && statusInfo.type === StatusType.PROBLEM_NOT_USER_SOLVABLE;

  return (
    <div className="px-8">
      <div className="py-16">
        <h1 className="text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl mb-8 md:mb-12">
          {statusInfo.label}
        </h1>

        {statusInfo.description && (
          <div className="flex flex-row items-center text-gray-400 pb-6">
            <div className="text-gray-600">
              <Markdown>{statusInfo.description}</Markdown>
            </div>
          </div>
        )}

        {statusInfo.type == StatusType.DELIVERED_SUCCESSFULLY && statusInfo.deliveredDate && (
          <div className="flex flex-row items-center text-gray-400 pb-2">
            <CalendarIcon className="w-6 mr-3" />
            <p className="text-gray-600">{deliveredText(statusInfo.deliveredDate)}</p>
          </div>
        )}

        {tracking.cash_on_delivery && (
          <div className="flex flex-row items-center text-gray-400 pb-2">
            <CashIcon className="w-6 mr-3" />
            <p className="text-gray-600">
              <Trans i18nKey="ticket.header.cash_on_delivery">
                .<strong>Spedizione con contrassegno:</strong> prepara i contanti per pagare il corriere alla consegna
              </Trans>
            </p>
          </div>
        )}

        <div className="pt-4">
          {postdeliveryAssistanceBoxVisible && (
            <InfoBox color="blue" icon={<InformationCircleIcon />}>
              <div className="flex flex-col md:flex-row items-center justify-between">
                <div>
                  <div className={`pb-2 text-blue-800 ${BoldLabel}`}>
                    <Markdown>{t('tracking_page.infoBoxPostDeliveryAssistance1')}</Markdown>
                  </div>
                  <div className={`text-blue-700 ${NormalText}`}>
                    <Markdown>
                      {t('tracking_page.infoBoxPostDeliveryAssistance2', {
                        date: format(statusInfo.postDeliveryAssistanceEndDate, 'eeee (d MMMM)', {
                          locale: dateFNSMappedLocales[customLanguageDetector()]
                        })
                      })}
                    </Markdown>
                  </div>
                </div>
                <DsButton color="blue" className="mt-2" leadingIcon={<ChatIcon />} onClick={onAssistanceButtonClick}>
                  {t('tracking_page.infoBoxPostDeliveryAssistanceButtonText')}
                </DsButton>
              </div>
            </InfoBox>
          )}

          {nonSolvableProblemBoxVisible && (
            <InfoBox color="blue" icon={<CheckCircleIcon />}>
              <div className={`pb-2 text-blue-800 ${BoldLabel}`}>
                <Markdown>{t('tracking_page.infoBoxProblemNotUserSolvable1')}</Markdown>
              </div>
              <div className={`text-blue-700 ${NormalText}`}>
                <Markdown>{t('tracking_page.infoBoxProblemNotUserSolvable2')}</Markdown>
              </div>
              {/* <Button color="blue" className="mt-6">
                {t('tracking_page.infoBoxProblemNotUserSolvableButtonText']}
              </Button> */}
            </InfoBox>
          )}

          {assistanceBoxVisible && (
            <div className="flex flex-col md:flex-row p-4 border border-blue-200 rounded-md bg-blue-50 max-w-3xl items-center justify-between mb-2">
              <StaticImage src={'../../images/support-faces.png'} alt="" height={46} width={110} objectFit="contain" />
              <div className={`text-sm leading-5 font-normal text-blue-700 pl-3 flex-1 text-center md:text-left`}>
                <p>{t('tracking_page.infoBoxProblemUserSolvable1')}</p>
                <p>{t('tracking_page.infoBoxProblemUserSolvable2')}</p>
              </div>
              <DsButton color="blue" className="mt-2" leadingIcon={<ChatIcon />} onClick={onAssistanceButtonClick}>
                {t('tracking_page.infoBoxProblemUserSolvableButtonText')}
              </DsButton>
            </div>
          )}

          {hasOpenTickets && openTickets.map((ticket) => <OpenTicketBox key={ticket.id} ticket={ticket} />)}

          {closedTickets && closedTickets.map((ticket) => <ClosedTicketBox key={ticket.id} ticket={ticket} />)}
        </div>
      </div>
    </div>
  );
};
